<template>
  <v-icon :size="primarySize" :color="primaryColor">{{ primaryIcon }}</v-icon>
</template>

<script>
export default {
  props: ["color", "size", "genderType"],
  data: () => {
    return {
      primaryColor: "#1867c0",
      primarySize: "30",
      primaryIcon: "",
    };
  },
  watch: {
    genderType() {
      this.reload();
    },
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      if (!this.genderType) return;
      if (typeof this.size != "undefined") {
        this.primarySize = this.size;
      }
      if (this.genderType === 1) {
        this.primaryIcon = "mdi-human-male";
        this.primaryColor = "#1867c0";
      } else {
        this.primaryIcon = "mdi-human-female";
        this.primaryColor = "#cf1eb3";
      }
    },
  },
};
</script>

<style scoped>
</style>