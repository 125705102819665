<template>
  <v-dialog v-model="dialogAdmission" width="1360px">
    <v-card>
      <v-container class="pa-0 ma-0 admission">
        <v-container class="pa-0 ma-0 pl-3 pr-3">
          <AdmissionInformation ref="AdmissionCard" />
        </v-container>
        <v-divider></v-divider>
        <v-container class="pa-0 ma-0 pl-3 pr-3 service-section">
          <ServiceInformation
            ref="ServiceInfoCard"
            @onError="showError"
            @onTreeItemsChanged="handleTreeItemsChanged"
          />
        </v-container>
        <v-card-actions class="justify-center pb-5">
          <v-btn text @click="cancelCreateAdmission">{{
            $t("Button.Cancel")
          }}</v-btn>
          <v-btn
            :loading="isAddmittingPatient"
            v-show="isAllowAdmission"
            text
            @click="patientAdmission"
            class="primary pa-4 ml-10"
            >{{ $t("Button.AdmitPatient") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AdmissionService from "../../../services/admission";
import AdmissionInformation from "./AdmissionInformation";
import ServiceInformation from "./ServiceInformation";
import BookingService from "../../../services/booking";

export default {
  props: {},
  components: {
    AdmissionInformation,
    ServiceInformation,
  },
  data: () => ({
    dialogAdmission: false,
    isAddmittingPatient: false,
    isAllowAdmission: false,
  }),
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    async renderAdmissionDetail(bookingID, patientID, serviceID) {
      this.dialogAdmission = true;
      const timeout = setTimeout(() => {
        // 1. Load admission info: booking info + patient info
        this.$refs.AdmissionCard.reloadAdmissionDetail(bookingID, patientID);
        // 2. Load default choosed service
        this.$refs.ServiceInfoCard.reloadServiceInformation(serviceID);
        clearTimeout(timeout);
      }, 200);
      this.$emit("onOpenTabQuene");
    },
    handleTreeItemsChanged(treeItems) {
      if (!treeItems || !treeItems.length) {
        this.isAllowAdmission = false;
        return;
      }
      this.isAllowAdmission = true;
    },
    async patientAdmission() {
      this.isAddmittingPatient = true;
      var booking = this.$refs.AdmissionCard.booking;
      var personalInfo = this.$refs.AdmissionCard.personalInfo;
      let admission = {
        clinicID: booking.clinicID,
        patientID: booking.patientID,
        bookingID: booking.bookingID,
        admissionType: booking.bookingType,
        patientType: personalInfo.patientType || 0,
      };

      let promotionID = this.$refs.ServiceInfoCard.promotionID || null;
      let treeItems = this.$refs.ServiceInfoCard.treeItems || [];
      let orderItems = [];
      treeItems.forEach((i) => {
        // add parent item => parentServiceID = null
        orderItems.push({
          itemName: i.name,
          isFree: i.isFree || false,
          targetID: i.itemID,
          typeID: i.type,
          staffPerformID: i.doctorID || null,
          roomID: i.roomID || null,
          parentServiceID: null,
          promotionID: i.promotionIDs.find((id) => id == promotionID) || null,
        });
        // add children item => parentServiceID = serviceID
        // but promotionID = null because price is included
        if (i.children && i.children.length) {
          i.children.forEach((child) => {
            orderItems.push({
              itemName: child.name,
              isFree: child.isFree || false,
              targetID: child.itemID,
              typeID: child.type,
              staffPerformID: child.doctorID || null,
              roomID: child.roomID || null,
              parentServiceID: child.serviceID || null,
              promotionID: null,
            });
          });
        }
      });
      const result = await AdmissionService.checkin(admission, orderItems);
      this.isAddmittingPatient = false;
      if (result.error) {
        this.showError("Can not create admission. Please try again later.");
        return;
      }
      result.orderItems = orderItems;
      let episodeRecordID = result.episodeRecord.id;
      this.$emit("onAdmissionSuccess", result, episodeRecordID);
      this.dialogAdmission = false;
      this.sockets.common.invoke("CheckInPatientIntoClinic", booking.clinicID);
      return result;
    },
    cancelCreateAdmission() {
      var booking = this.$refs.AdmissionCard.booking;
      // if booking is a walkin booking then remove
      if (booking.isWalkIn) {
        BookingService.removeBooking(booking.bookingID);
      }
      this.$emit(`onCancelAdmission`);
      this.dialogAdmission = false;
    },
  },
};
</script>

<style scoped>
.text-space {
  letter-spacing: 0.5px;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.admission.container {
  max-width: 1360px !important;
}
.admission .container {
  max-width: 1360px !important;
}
.admission .service-section {
  /* max-height: 670px; */
  /* overflow-y: scroll; */
}
</style>