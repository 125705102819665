import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas);

export default {
  searchAdmissionTypes() {
    return httpClient.get('/AdmissionType/Search');
  },
  checkOrCreateOccupation(name = "") {
    var query = { name }
    return httpClient.post('/Patient/Option/Occupation/CheckOrCreate', query);
  },
  checkOrCreateEthnicity(name = "") {
    var query = { name }
    return httpClient.post('/Patient/Option/Ethnicity/CheckOrCreate', query);
  },
}