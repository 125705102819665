var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0 pt-5 pb-2"},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowUpdateServices),expression:"allowUpdateServices"}],staticClass:"pa-0 ma-0 fw h-4"},[_c('p',{staticClass:"fl lh-4 mr-3 row-title"},[_vm._v(_vm._s(_vm.$t("Common.Package"))+":")]),_c('v-select',{staticClass:"fl mr-3",staticStyle:{"width":"200px"},attrs:{"items":_vm.itemTypes,"filled":"","dense":"","full-width":"","label":"Item Type","append-icon":"mdi-book-outline"},model:{value:(_vm.itemType),callback:function ($$v) {_vm.itemType=$$v},expression:"itemType"}}),_c('v-autocomplete',{staticClass:"fl mr-3",staticStyle:{"width":"320px"},attrs:{"items":_vm.items,"filled":"","dense":"","full-width":"","label":'Choose ' + _vm.itemTypes.find(function (i) { return i.value == _vm.itemType; }).text,"append-icon":"mdi-package-variant-closed"},model:{value:(_vm.itemID),callback:function ($$v) {_vm.itemID=$$v},expression:"itemID"}}),_c('v-btn',{staticClass:"h-4 lh-4",attrs:{"loading":_vm.isAddingMoreItem,"elevation":"2"},on:{"click":function($event){_vm.isAddingMoreItem = true;
        _vm.addIntoTreeView(_vm.itemID, _vm.itemType);}}},[_vm._v(_vm._s(_vm.$t("Button.Add")))]),_c('v-select',{staticClass:"fr mr-3",staticStyle:{"width":"250px"},attrs:{"items":_vm.filteredPromotions,"filled":"","dense":"","full-width":"","label":"Promotion Code","append-icon":"mdi-code-tags"},model:{value:(_vm.promotionID),callback:function ($$v) {_vm.promotionID=$$v},expression:"promotionID"}}),_c('p',{staticClass:"fr mr-4 mt-2"},[_vm._v(_vm._s(_vm.$t("Common.Promotion"))+":")])],1),_c('v-container',{staticClass:"pa-0 ma-0 fw mt-3"},[_c('p',{staticClass:"fl lh-1 row-text",staticStyle:{"width":"calc(100% - 0px)"}},[_c('v-toolbar',{attrs:{"dense":"","dark":"","flat":""}},[_c('p',{staticClass:"fl ma-0 pl-4",style:({
            width:
              _vm.reRenderWidth == true
                ? '775px'
                : _vm.allowFreeSelection
                ? '428px'
                : _vm.allowFreeSelection
                ? '435px'
                : '495px',
          })},[_vm._v(" "+_vm._s(_vm.$t("Common.PackageName"))+" ")]),(_vm.allowFreeSelection)?_c('p',{staticClass:"fl ma-0 tc",staticStyle:{"width":"60px"}},[_vm._v(" "+_vm._s(_vm.$t("Common.Free"))+" ")]):_vm._e(),_c('p',{staticClass:"fl ma-0 tc",staticStyle:{"width":"120px"}},[_vm._v(_vm._s(_vm.$t("Common.Type")))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowUpdateServices),expression:"allowUpdateServices"}],staticClass:"fl ma-0 tc",staticStyle:{"width":"220px"}},[_vm._v(" "+_vm._s(_vm.$t("Doctor.Doctor"))+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowUpdateServices),expression:"allowUpdateServices"}],staticClass:"fl ma-0 tc",staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(_vm.$t("Common.Room"))+" ")]),_c('p',{staticClass:"fl ma-0 tc",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(_vm.$t("Common.OriginalPrice"))+" ")]),_c('p',{staticClass:"fl ma-0 tc",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(_vm.$t("Common.DiscountedPrice"))+" ")])]),_c('v-skeleton-loader',{attrs:{"height":"200px","loading":_vm.isLoadingServices,"type":"table-heading, list-item, list-item, list-item"}},[_c('v-treeview',{attrs:{"open-all":"","open-on-click":"","selectable":_vm.allowUpdateServices,"dense":"","return-object":"","selected-color":"primary","items":_vm.treeItems,"open":_vm.opens},on:{"update:open":function($event){_vm.opens=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"append",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"fr ma-0 h-4 lh-4 tc",class:{ success: item.isFree || item.isPromoted },staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(item.isFree ? _vm.$t("Common.Free") : item.isParent ? item.discounted : "included")+" ")]),_c('p',{staticClass:"fr ma-0 h-4 lh-4 tc",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(item.isParent ? item.price : "included")+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowUpdateServices),expression:"allowUpdateServices"}],staticClass:"fr ma-0 h-4 lh-4 tc",staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(item.room)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowUpdateServices),expression:"allowUpdateServices"}],staticClass:"fr ma-0 h-4 lh-4 tc",staticStyle:{"width":"220px"}},[(!item.doctorOptions.length)?_c('span',[_vm._v(_vm._s(item.doctor))]):_c('v-select',{staticStyle:{"width":"90%","margin-left":"5%"},attrs:{"items":item.doctorOptions,"dense":"","outlined":""},on:{"change":function($event){return _vm.handleChangeOrderItemDoctor($event, item)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,true),model:{value:(item.doctorID),callback:function ($$v) {_vm.$set(item, "doctorID", $$v)},expression:"item.doctorID"}})],1),_c('p',{staticClass:"fr ma-0 h-4 lh-4 tc",staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(item.typeName)+" ")]),(_vm.allowFreeSelection && item.isParent)?_c('p',{staticClass:"fr ma-0 h-4 lh-4 tc",staticStyle:{"width":"50px"}},[_c('v-checkbox',{staticStyle:{"margin-top":"5px"},attrs:{"color":"success"},on:{"change":_vm.displayPriceStrings},model:{value:(item.isFree),callback:function ($$v) {_vm.$set(item, "isFree", $$v)},expression:"item.isFree"}})],1):_vm._e()]}}]),model:{value:(_vm.selections),callback:function ($$v) {_vm.selections=$$v},expression:"selections"}})],1),_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"grey lighten-4"}},[_c('p',{staticClass:"fl ma-0 tr bold",staticStyle:{"width":"1055px"}},[_vm._v(" "+_vm._s(_vm.$t("Common.Total"))+" ")]),_c('p',{staticClass:"fl ma-0 tr bold",staticStyle:{"width":"220px"}},[_vm._v(" "+_vm._s(_vm.totalPriceString)+" ")])]),_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"grey lighten-4"}},[_c('p',{staticClass:"fl ma-0 tr bold",staticStyle:{"width":"1055px"}},[_vm._v(" "+_vm._s(_vm.$t("Common.Discount"))+" ")]),_c('p',{staticClass:"fl ma-0 tr bold",staticStyle:{"width":"220px"}},[_vm._v(" - "+_vm._s(_vm.totalDiscountPriceString)+" ")])]),_c('v-toolbar',{attrs:{"dense":"","dark":"","flat":""}},[_c('p',{staticClass:"fl ma-0 tr bold",staticStyle:{"width":"1055px"}},[_vm._v(" "+_vm._s(_vm.$t("Common.FinalPrice"))+" ")]),_c('p',{staticClass:"fl ma-0 tr bold",staticStyle:{"width":"220px"}},[_vm._v(" "+_vm._s(_vm.finalPriceString)+" ")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }