var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isShow)?_c('v-sheet',{attrs:{"rounded":"lg"}},[_c('v-row',{staticClass:"pl-3 pr-3"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("Common.LastVisitsOf"))+" "),_c('b',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.patientInfo.patientName))]),_vm._v(":")])],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.skeletonLoadingTable,"height":"500","type":"table"}},[_c('v-data-table',{staticClass:"app-custom-table",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.tableOpts,"server-items-length":_vm.totals,"footer-props":{
          tableFooterProps: _vm.tableFooterProps,
          'items-per-page-text': _vm.$t('Common.RowsPerPage'),
        },"disable-sort":"","mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.tableOpts=$event},"update:items-per-page":_vm.renderLastVisits,"update:page":_vm.onChangePagination},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"format-col"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"target":"_blank","small":""},on:{"click":function($event){return _vm.handleOpenPrintMedication(item.episodeRecordID)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-medical-bag ")])]}}],null,true)},[_c('span',[_vm._v("Print Medication Prescription")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"target":"_blank","small":""},on:{"click":function($event){return _vm.handleOpenPrintGlass(item.episodeRecordID)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-glasses ")])]}}],null,true)},[_c('span',[_vm._v("Print Glass Prescription")])])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" Patient has no visits yet! ")]},proxy:true}],null,true)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }