import HttpClient from '../plugins/httpClient'
//import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas);

const prefix = '/HealthInsurance';


export default {
    getDetailHealthInsurance: (id) => httpClient.get(`${prefix}/Get/${id}`, {})
}
