<template>
  <div>
    <v-sheet rounded="lg" v-if="isShow">
      <v-row class="pl-3 pr-3">
        <v-card-title>
          {{ $t("Common.LastVisitsOf") }}
          <b class="ml-2">{{ patientInfo.patientName }}</b
          >:</v-card-title
        >
      </v-row>
      <!-- list booking table -->
      <v-skeleton-loader
        :loading="skeletonLoadingTable"
        height="500"
        type="table"
      >
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="tableOpts"
          :server-items-length="totals"
          :footer-props="{
            tableFooterProps,
            'items-per-page-text': $t('Common.RowsPerPage'),
          }"
          @update:items-per-page="renderLastVisits"
          @update:page="onChangePagination"
          disable-sort
          class="app-custom-table"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="format-col">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    target="_blank"
                    @click="handleOpenPrintMedication(item.episodeRecordID)"
                    class="ma-3"
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-medical-bag
                  </v-icon>
                </template>
                <span>Print Medication Prescription</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleOpenPrintGlass(item.episodeRecordID)"
                    target="_blank"
                    class="ma-3"
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-glasses
                  </v-icon>
                </template>
                <span>Print Glass Prescription</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:no-data> Patient has no visits yet! </template>
        </v-data-table>
      </v-skeleton-loader>
      <!-- end list booking table -->
    </v-sheet>
  </div>
</template>

<script>
import PatientService from "../../../services/patient";
import { TABLE_ROWS_PER_PAGE, TABLE_OPTS } from "../../../plugins/constant";

import moment from "moment";

export default {
  props: {
    itemsPerPage: {
      type: Number,
      default: 6,
    },
  },
  components: {},
  data() {
    return {
      showDialog: false,
      isShow: false,
      // placeholder lazy loading
      skeletonLoadingTable: true,
      // booking list table
      headers: [
        { text: this.$t("Common.LastVisit"), value: "admissionTime" },
        { text: this.$t("Clinic.Clinic"), value: "clinicName" },
        // { text: "Patient", value: "patientName" },
        { text: this.$t("Service.Service"), value: "serviceName" },
        { text: this.$t("Common.PrimaryDiagnosis"), value: "diagnosisString" },
        { text: this.$t("Common.PrimaryDiagnosis"), value: "diagnosisString" },
        { text: "", value: "actions", sortable: false },
      ],
      totals: 0,
      items: [],
      tableOpts: { ...TABLE_OPTS, itemsPerPage: this.itemsPerPage },
      tableFooterProps: {
        itemsPerPageOptions: TABLE_ROWS_PER_PAGE,
      },
      patientInfo: {
        patientID: null,
        patientName: null,
      },
    };
  },
  watch: {
    patientID: {
      handler() {
        this.renderLastVisits();
      },
    },
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    hide() {
      this.isShow = false;
    },
    handleOpenPrintMedication(id) {
      let episodeRecordID = id;
      let patientID = this.patientInfo.patientID;
      let srcLinkPrint = `/medicine-print/${episodeRecordID}/${patientID}`;
      window.open(
        srcLinkPrint,
        "Medicine Prescription",
        "width=800, height=500"
      );
    },
    handleOpenPrintGlass(id) {
      let episodeRecordID = id;
      let patientID = this.patientInfo.patientID;
      console.log(patientID);
      let srcLinkPrint = `/glass-print/${episodeRecordID}/${patientID}`;
      window.open(srcLinkPrint, "Glass Prescription", "width=800, height=500");
    },
    async renderLastVisits(patientID, patientName) {
      if (!patientID) return;
      patientName = patientName || "";
      // cache local data
      this.patientInfo = {
        patientID,
        patientName,
      };
      this.skeletonLoadingTable = true;
      this.isShow = true;

      const { page, itemsPerPage } = this.tableOpts;
      var keySearch = "";

      var result = await PatientService.searchLastVisits(
        patientID,
        keySearch,
        page,
        itemsPerPage
      );
      console.log(result);
      this.skeletonLoadingTable = false;

      if (result.error) {
        this.showError("Cannot get list bookings. Please try again later!");
        return;
      }

      this.items = result.items.map((item) => {
        item.serviceName =
          item.serviceItems && item.serviceItems[0].description;
        item.admissionTime = moment(item.admissionTime).format("DD/MM/YYYY");
        item.diagnosisString =
          item.diagnosisResults
            .filter((d) => d.diagnosisCode == "Primary")
            .map((d) => d.icDxDescription)
            .join(" + ") || "NA";
        item.episodeRecordID = item.episodeRecordIDs[0];
        return item;
      });
      console.log(this.items);
      this.totals = result.totals;
    },
    async onChangePagination(value) {
      this.tableOpts.page = value;
      var { patientID, patientName } = this.patientInfo;
      await this.renderLastVisits(patientID, patientName);
    },
  },
};
</script>

<style scoped>
.format-col {
  width: 90px;
}
</style>