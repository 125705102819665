<template>
    <v-card elevation="0">
        <Container class="w-100">      
            <v-row justify="space-between">
                <!-- Radio check insurance YES | NO-->
                <v-col cols="12" md="4" sm="12" sx="12">
                    <Item>
                        <Title>{{ $t('Common.Insurance') }} (*)</Title>
                        <Content>
                            <v-radio-group
                                :disabled="disabled"
                                v-model="data.havingInsurance"
                                row
                                >
                                <v-radio v-for="(item, index) in insuranceConfirm" :key="index"
                                    :label="$t( `${ item.label }` )"
                                    :value="item.value"
                                ></v-radio>
                            </v-radio-group>
                        </Content>
                    </Item>
                </v-col>
                <!-- Confirm insurance is PUBLIC OR PRIVATE -->

                <v-col cols="12" md="4" sm="12" sx="12">
                    <Item v-if="data.havingInsurance">
                        <Title>{{ $t('Common.InsuranceType') }} (*)</Title>
                        <!--  Insurance Type -->
                        <Content >
                            <v-select
                                :disabled="disabled"
                                v-model="data.publicInsurance"
                                :items="insuranceType"
                                label="Type"
                                item-text="label"
                                item-value="value"
                                single-line
                            ></v-select>
                        </Content>

                        <!-- Insurance Company Selection -->
                        <Content class="mt-2">
                            <template>
                                <InsuranceCompanySelected
                                    :disabled="disabled"       
                                    v-if="!data.publicInsurance"        
                                    v-model="data.insuranceCompanyID"   
                                    :selectedID="data.insuranceCompanyID"    
                                    />
                            </template>   
                        </Content>
                    </Item>
                </v-col>
                
                <!-- Insurance Number -->
                <v-col cols="12" md="4" sm="12" sx="12">
                    <Item v-if="data.havingInsurance">
                        <Title>{{ $t('Common.InsuranceNumber') }} (*)</Title>
                        <Content v-if="data.havingInsurance">
                            <v-text-field 
                                :disabled="disabled"         
                                clearable
                                v-model="data.healthInsuranceNumber"      
                            ></v-text-field>
                        </Content>
                        <Content class="mt-2">
                            <template v-if="data.havingInsurance">
                                <v-btn
                                    x-small
                                    depressed
                                    color="primary"
                                    v-if="data.publicInsurance"
                                    @click="loadCheckInsurance"
                                    fab
                                    >
                                    <v-icon>
                                        mdi-text-box-search-outline
                                    </v-icon>
                                </v-btn>
                            </template>                     
                        </Content>
                    </Item>
                </v-col>
            </v-row>   
        </Container>

        <Container v-if="data.checkInsuranceNumber">
            <CheckSocialInsurance    
                v-bind="$props"
                :isPublicInsurance="data.publicInsurance"
                :healthInsuranceNumber="data.healthInsuranceNumber"
            />
        </Container>
    </v-card>
   
</template>

<script>
import { 
    Title,
    Container,
    Content,
    Item
} from './css/style';
import InsuranceCompanySelected from './components/InsuranceCompanySelect';
import CheckSocialInsurance from './components/socialInsurance/SocialInsurance';
import HealthInsuranceBusiness from '../../../services/healthInsurance';
import i18n from "@/plugins/i18n";

export default {
    components: {
        Title,
        Container,
        Content,
        Item,
        InsuranceCompanySelected,
        CheckSocialInsurance
    },
    props: {
        birthDate: {
            type: String,
            default: ''
        },
        fullName: {
            type: String,
            default: ''
        },

        patientID: {
            type: Number,
            default: null
        },
        healthInsuranceID: {
            type: Number,
            default: null
        },
        havingInsurance: {
            type: Boolean,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        'data': {
            deep: true,
            handler() {
                  this.returnDataModel()
            }    
        },   
        healthInsuranceID() {
            if (this.healthInsuranceID == null) { // ==> No Insurance
                this.data = {
                    havingInsurance: false,
                    publicInsurance: true,
                    insuranceCompanyID: null,
                    healthInsuranceNumber: null,
                    checkInsuranceNumber: false
                }
            } else {
                this.getDetailHealthInsurance(); // Having insurance
            }
        },
        havingInsurance() {
            if (this.havingInsurance == null) // create new HealthInsurance record => Create New Patient Information
            {
                this.refreshDefaultData();
                return;
            }
            if (this.havingInsurance == false) { // Case: No having HealthInsuranceRecord => Update patient information
                this.data = {
                    havingInsurance: false,
                    publicInsurance: true,
                    insuranceCompanyID: null,
                    healthInsuranceNumber: null,
                    checkInsuranceNumber: false
                }
            }
            if (this.havingInsurance == true) { // Case: Having HealthInsuranceRecord => Update patient information
                if (this.healthInsuranceID != null) {
                    // load records
                  
                    this.getDetailHealthInsurance()
                } else {
                    this.data = {
                        ...this.data,
                        havingInsurance: false
                    }
                }
            }
        }
    },
    data: () => {
        return {
            insuranceConfirm: [
                {
                    label: "Button.Yes",
                    value: true
                },
                { 
                    label: "Button.No",
                    value: false
                }
            ],

            insuranceType: [
                {
                    label: i18n.t('Button.Public'),
                    value: true,
                },
                { 
                    label: i18n.t('Button.Private'),
                    value: false
                }
            ],

            data: {
                havingInsurance: true,
                publicInsurance: true,
                insuranceCompanyID: null,
                healthInsuranceNumber: null,
                checkInsuranceNumber: false
            },

            rules: [
                value => (value && value.length >= 5) || 'Min 3 characters',
            ],

            selectedInsuranceCompanyID: null
        }
    },

    methods: {
        loadCheckInsurance() {
            this.data.checkInsuranceNumber = true;
        },
        
        returnDataModel() {          
            this.$emit('input', this.data);
        },

        refreshDefaultData() {
            this.data = {  
                havingInsurance: true,
                publicInsurance: true,
                insuranceCompanyID: null,
                healthInsuranceNumber: null,
                checkInsuranceNumber: false
            }
        },

        async getDetailHealthInsurance() {
            var result = await HealthInsuranceBusiness.getDetailHealthInsurance(this.healthInsuranceID)
            if (result.error) {
                return;
            }

            this.data = {
                havingInsurance: true,
                publicInsurance: result.publicInsurance,
                insuranceCompanyID: result.insuranceCompanyID,
                healthInsuranceNumber: result.healthInsuranceNumber,
                checkInsuranceNumber: false
            };
        }
    }
}
</script>

<style>

</style>