<template>
  <v-col cols="12" md="12" class="pt-0 mt-0">
    <v-row>
      <v-col cols="3" md="3" class="pt-0 mt-0">
        <v-text-field
          v-model="value.idSearch"
          append-icon="mdi-key"
          :label="$t('Patient.PID')"
          dense
          outlined
          @keyup.enter="changeSearchOptions"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="3" class="pt-0 mt-0">
        <v-text-field
          v-model="value.aidSearch"
          :label="$t('Patient.AID')"
          dense
          outlined
          @keyup.enter="changeSearchOptions"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6"> </v-col>
    </v-row>
    <!-- <hr class="mb-4 fix-search-bar" /> -->
    <v-row
      style="
        border-top: 0.5px solid #b5b3b373;
        padding-top: 16px;
        margin-top: -20px;
      "
    >
      <v-col cols="12" md="3" class="pt-0 mt-0">
        <v-text-field
          v-model="value.keySearch"
          ref="keySearch"
          append-icon="mdi-magnify"
          :label="$t('Patient.SearchName')"
          dense
          outlined
          @keyup.enter="changeSearchOptions"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pt-0 mt-0">
        <v-text-field
          v-model="value.dobSearch"
          :label="$t('Patient.DOB')"
          dense
          outlined
          @change="changeSearchOptions"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pt-0 mt-0">
        <v-text-field
          v-model="value.addressSearch"
          :label="$t('Common.Address')"
          dense
          outlined
          @change="changeSearchOptions"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pt-0 mt-0">
        <v-text-field
          v-model="value.phoneSearch"
          ref="keySearch"
          :label="$t('Patient.Phone')"
          dense
          outlined
          @keyup.enter="changeSearchOptions"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
  <!-- <v-row>
    <v-col cols="12" md="3" class="pt-0 mt-0">
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="value.keySearch"
            ref="keySearch"
            append-icon="mdi-magnify"
            :label="$t('Patient.SearchName')"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="9" class="pt-0 mt-0">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="value.idSearch"
            :label="$t('Patient.PID')"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="value.aidSearch"
            :label="$t('Patient.AID')"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="value.dobSearch"
            :label="$t('Patient.DOB')"
            dense
            outlined
            @change="changeSearchOptions"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="value.phoneSearch"
            ref="keySearch"
            :label="$t('Patient.Phone')"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row> -->
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          keySearch: "",
          idSearch: "",
          aidSearch: "",
          phoneSearch: "",
          dobSearch: "",
          addressSearch: "",
        };
      },
    },
  },
  components: {},
  computed: {},
  data: () => {
    return {};
  },
  async mounted() {},
  methods: {
    changeSearchOptions() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style scoped>
.fix-search-bar {
  margin-top: -20px;
  opacity: 0.3;
}
</style>