<template>
  <v-dialog
    v-model="showDialog"
    max-width="1660px"
    scrollable
    class="d-print-none"
  >
    <v-card
      color="white"
      min-height="1050px"
      height="auto"
      class="d-print-none"
    >
      <v-alert
        :type="status.type || 'error'"
        icon="mdi-cloud-alert"
        dismissible
        v-model="status.show"
        transition="scale-transition"
      >
        {{ status.message }}
      </v-alert>
      <v-col background-color="transparent" color="black" grow>
        <v-row no-gutters>
          <v-col cols="auto" sm="6">
            <v-card
              class="pa-4 elevation-0 pt-0"
              outlined
              tile
              style="height: auto"
            >
              <v-card-title class="justify-center">{{
                $t("Common.PersonalInformation")
              }}</v-card-title>
              <v-form ref="personalForm">
                <PatientPersonal
                  ref="PatientPersonal"
                  :personalInfo="personalInfo"
                  :editable="editable"
                  v-model="personalInfo"
                  class="d-print-none"
                />
              </v-form>

              <!-- Health Insurance -->
              <v-card-title class="justify-center">
                {{ $t("Common.HealthInsurrance") }}
              </v-card-title>

              <HealthInsuranceBox
                :birthDate="BirthDate"
                :fullName="FullName"
                :healthInsuranceID="healthInsuranceID"
                :havingInsurance="havingInsurance"
                :disabled="!editable"
                ref="healthInsurance"
                v-model="healthInsurance"
              />
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" style="margin-left: -1px">
            <v-card
              class="pa-4 elevation-0 pt-0"
              outlined
              tile
              style="height: 745px"
            >
              <v-card-title class="justify-center">{{
                $t("Common.RelativeInformation")
              }}</v-card-title>
              <v-form ref="relativeForm">
                <PatientRelative
                  :relativeInfo="relativeInfo"
                  :editable="editable"
                />
              </v-form>
              <v-card-title v-if="false" class="justify-center">{{
                $t("Common.MedicalConditionInformation")
              }}</v-card-title>
              <v-form v-if="false" ref="historyForm">
                <PatientHistory
                  :personalHistory="historyInfo"
                  :editable="editable"
                />
              </v-form>

              <v-card-actions class="justify-center">
                <v-btn
                  v-if="!editable"
                  :loading="isLoading"
                  @click="handleEditClick"
                  class="mr-3 pa-2 pr-4"
                >
                  <v-icon dark class="mr-2"> mdi-pencil-outline </v-icon>
                  {{ $t("Button.EditProfile") }}
                </v-btn>
                <v-btn
                  v-if="editable"
                  @click="showDialog = false"
                  class="mr-3 pa-2 pr-4"
                >
                  {{ $t("Button.Cancel") }}
                </v-btn>
                <v-btn
                  v-if="editable"
                  :loading="isCreating"
                  @click="savePatientProfile()"
                  class="mr-3 pa-2 pr-4"
                  color="primary"
                >
                  <v-icon dark class="mr-2"> mdi-content-save-outline </v-icon>
                  {{ $t("Button.CreateProfile") }}
                </v-btn>
              </v-card-actions>
              <div
                style="max-height: 500px; overflow-y: auto; overflow-x: hidden"
              >
                <PatientLastVisits ref="PatientLastVisits" :itemsPerPage="10" />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import PatientService from "../../../services/patient";
import PatientPersonal from "./PatientPersonalInformation";
import PatientRelative from "./PatientRelativeInformation";
import PatientHistory from "./PatientHistoryInformation";
import PatientLastVisits from "./PatientLastVisits";
import { CRUD_TYPE } from "../../../plugins/constant";
import { getGenderStatusCodeOptions } from "../../../plugins/helper";
import HealthInsuranceBox from "../InsuranceInput/InsuranceBox";

export default {
  data: () => ({
    popupType: CRUD_TYPE.Read,
    isLoading: false,
    isCreating: false,
    patientID: null,
    editable: false,
    showDialog: false,
    status: {
      type: "error",
      show: false,
      message: "",
    },
    personalInfo: {
      patientID: 0,
      fullName: "",
      phone: "",
      email: "",
      address: "",
      birthDate: "",
      avatar: "",
      genderType: 1,
      identificationNumber: "",
      occupationID: null,
      nationalityID: null,
      ethnicityID: null,
    },
    relativeInfo: {},
    historyInfo: {},
    healthInsurance: {},

    healthInsuranceID: null,
    havingInsurance: null,
  }),
  components: {
    PatientPersonal,
    PatientRelative,
    PatientHistory,
    PatientLastVisits,
    HealthInsuranceBox,
  },
  computed: {
    GenderTypeCodes: () => getGenderStatusCodeOptions(),
    FullName() {
      return this.personalInfo.fullName;
    },
    BirthDate() {
      return this.personalInfo.birthDate;
    },
  },
  async mounted() {},
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    handleEditClick() {
      var { patientID } = this.personalInfo;
      if (patientID) this.openEdit(patientID);
    },
    // ----  Click POP UP View ----
    async openPopup(patientID) {
      this.editable = false;
      this.showDialog = true;
      this.patientID = patientID;
      await this.renderPatientDetail();
      var fullName = this.personalInfo.fullName;
      this.$refs.PatientLastVisits.renderLastVisits(patientID, fullName);
      this.popupType = CRUD_TYPE.Read;
    },
    // ----  Click POP UP creation ----
    async openCreate() {
      this.editable = true;
      this.showDialog = true;
      this.patientID = null;
      this.havingInsurance = null;
      this.healthInsuranceID = null;
      this.cleanForm();
      if (this.$refs.PatientLastVisits) {
        this.$refs.PatientLastVisits.hide();
      }
      this.popupType = CRUD_TYPE.Create;
    },
    // ---- Click to view Patient Detail ----
    async openEdit(patientID) {
      this.editable = true;
      this.showDialog = true;
      this.patientID = patientID;
      await this.renderPatientDetail();
      var fullName = this.personalInfo.fullName;
      this.$refs.PatientLastVisits.renderLastVisits(patientID, fullName);
      this.popupType = CRUD_TYPE.Update;
    },
    async renderPatientDetail() {
      // refresh null
      this.healthInsuranceID = null;
      this.havingInsurance = null;

      if (!this.patientID) {
        return;
      }
      this.isLoading = true;
      var result = await PatientService.getPatientDetail(this.patientID);
      this.isLoading = false;
      if (result.error) {
        this.showError(this.$t("Toast.CannotGetClinicDetail"));
        return;
      }
      const {
        personalInfo,
        historyInfo,
        relativeInfo,
        patientIDString,
        healthInsurance,
      } = result;
      const { fullName } = personalInfo;
      personalInfo.fullName = fullName;
      this.personalInfo = { ...personalInfo, patientIDString };
      this.historyInfo = historyInfo || {
        personalHistoryID: 0,
        patientID: 0,
        chronicDiseaseCode: "",
        diagnosisDescription: "",
      };
      this.relativeInfo = relativeInfo || {
        patientRelativeID: 0,
        patientID: 0,
        name: "",
        gender: "",
        address: "",
        phone: "",
        email: "",
        dob: "",
        relationship: "",
      };
      this.healthInsurance = healthInsurance || {
        // case: No record HealthInsurance ==> NULL
        havingInsurance: false,
        publicInsurance: false,
        insuranceCompanyID: null,
        healthInsuranceNumber: null,
      };
      this.disabledInsurance = false; // Allow Edit
      if (this.healthInsurance.healthInsuranceNumber != null) {
        this.havingInsurance = true;
        this.healthInsuranceID = this.healthInsurance.healthInsuranceID;
      } else {
        this.havingInsurance = false;
        this.healthInsuranceID = null;
      }
    },
    async savePatientProfile() {
      if (!this.$refs.personalForm.validate()) {
        this.showError(this.$t("Toast.ValidationSaveErrorPatientProfile"));
        return;
      }
      // prepare data object
      var patientData = {
        personalInfo: this.personalInfo,
        historyInfos: [],
        relativeInfos: [],
        healthInsurance: this.$refs.healthInsurance.data,
      };
      if (this.relativeInfo.name) {
        patientData.relativeInfos.push(this.relativeInfo);
      }
      if (
        this.historyInfo.chronicDiseaseCode ||
        this.historyInfo.diagnosisDescription
      ) {
        patientData.historyInfos.push(this.historyInfo);
      }
      var result;
      // loading
      this.isCreating = true;
      console.log(this.popupType);
      // if popup type == Update
      if (this.popupType == CRUD_TYPE.Update) {
        result = await PatientService.updatePatient(
          this.patientID,
          patientData
        );
      }
      // else then => Create
      else {
        result = await PatientService.createPatient(patientData);
      }
      this.isCreating = false;
      if (result.error) {
        this.showError(this.$t("Toast.CannotEditPatientProfile"));
        return;
      }
      // If update patient profile
      if (this.patientID) {
        this.$emit("onUpdateSuccess", result.personalInfo.patientID);
      }
      // else create patient profile
      else {
        this.$emit("onCreateSuccess", result.personalInfo.patientID);
      }
      this.showDialog = false;
    },
    cleanForm() {
      this.personalInfo = {
        patientID: 0,
        athenaID: null,
        fullName: "",
        phone: "",
        email: "",
        address: "",
        birthDate: "",
        avatar: "",
        genderType: "",
        identificationNumber: "",
        occupationID: null,
        nationalityID: null,
        ethnicityID: null,
      };
      this.relativeInfo = {
        patientRelativeID: 0,
        patientID: 0,
        name: "",
        gender: "",
        address: "",
        phone: "",
        email: "",
        dob: "",
        relationship: "",
      };
      this.historyInfo = {
        personalHistoryID: 0,
        patientID: 0,
        chronicDiseaseCode: "",
        diagnosisDescription: "",
      };
      // reset form dob
      if (this.$refs.PatientPersonal) {
        this.$refs.PatientPersonal.dobEdit = "";
      }
    },
  },
};
</script>

<style scoped>
.text-space {
  letter-spacing: 0.5px;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>