<template>
  <v-row class="pl-3 pr-3 d-print-none">
    <v-card-title>{{ $t("Patient.PatientInformation") }} </v-card-title>
    <v-spacer></v-spacer>
    <!-- show full profile checkbox -->
    <v-btn
      @click="handleFullProfileClick"
      class="mt-3 mr-3 pa-2 pr-4 mb-2"
      style="float: right"
    >
      <v-icon dark class="mr-2"> mdi-eye-outline </v-icon>
      {{ $t("Button.FullProfile") }}
    </v-btn>
    <!-- edit button -->
    <v-btn
      @click="$emit('onEditClick')"
      class="mt-3 mr-3 pa-2 pr-4 mb-2"
      style="float: right"
      color="primary"
    >
      <v-icon dark class="mr-2"> mdi-pencil-outline </v-icon>
      {{ $t("Button.EditProfile") }}
    </v-btn>
    <v-alert
      :type="status.type || 'error'"
      width="100%"
      icon="mdi-cloud-alert"
      dismissible
      v-model="status.show"
      transition="scale-transition"
    >
      {{ status.message }}
    </v-alert>

    <v-expansion-panels focusable multiple v-model="pannels">
      <v-expansion-panel>
        <v-expansion-panel-header>{{
          $t("Patient.PatientInformation")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <PatientPersonal :personalInfo="personalInfo" />
          <div class="d-block w-100">
            <HealthInsuranceBox
              v-bind:disabled="true"
              :birthDate="BirthDate"
              :fullName="FullName"
              :healthInsuranceID="healthInsuranceID"
              :havingInsurance="havingInsurance"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <PatientProfilePopup ref="patientProfilePopup" />
  </v-row>
</template>

<script>
import PatientService from "../../../services/patient";
import PatientPersonal from "../PatientInfo/PatientPersonalInformation";
import PatientProfilePopup from "../PatientInfo/PatientProfilePopup";
import { getGenderStatusCodeOptions } from "../../../plugins/helper";
import HealthInsuranceBox from "../InsuranceInput/InsuranceBox";
import moment from "moment";

export default {
  props: {
    patientID: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PatientPersonal,
    PatientProfilePopup,
    HealthInsuranceBox,
  },
  computed: {
    GenderTypeCodes: () => getGenderStatusCodeOptions(),
    FullName() {
      return typeof this.personalInfo.fullName != "undefined"
        ? this.personalInfo.fullName
        : "";
    },
    BirthDate() {
      return typeof this.personalInfo.birthDate != "undefined"
        ? moment(this.personalInfo.birthDate).format("MM/DD/YYYY")
        : "";
    },
  },
  watch: {
    patientID: {
      handler(val) {
        this.renderPatientDetail(val);
      },
    },
  },
  data() {
    return {
      pannels: [0],
      status: {
        type: "error",
        show: false,
        message: "",
      },
      personalInfo: {},

      healthInsuranceID: null,
      havingInsurance: null,
    };
  },
  async created() {},
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    handleFullProfileClick() {
      this.$refs.patientProfilePopup.openPopup(this.patientID);
    },
    async renderPatientDetail(patientID) {
      if (!patientID) {
        return;
      }
      this.healthInsuranceID = null; // refresh them
      this.havingInsurance = null; // refresh them

      var result = await PatientService.getPatientDetail(patientID);
      if (result.error) {
        this.showError(this.$t("Toast.CannotGetClinicDetail"));
        return;
      }
      console.log(result);
      const { personalInfo, patientIDString, healthInsurance } = result;
      const { fullName } = personalInfo;
      personalInfo.fullName = fullName;
      this.personalInfo = { ...personalInfo, patientIDString };
      // change value when receive from response
      this.healthInsuranceID =
        healthInsurance != null ? healthInsurance.healthInsuranceID : null;
      this.havingInsurance = healthInsurance != null ? true : false;
    },
  },
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>