export const Information = [
    {
        name: 'hoTen',
        title: 'Họ tên',
        value: ''
    },
    {
        name: 'ngaySinh',
        title: 'Ngày Sinh',
        value: ''
    },
    {
        name: 'gioiTinh',
        title: 'Giới tính',
        value: ''
    },
    {
        name: 'diaChi',
        title: 'Địa chỉ',
        value: ''
    }
];

export const Status = [
    {
        name: 'maKetQua',
        title: 'Mã kết quả',
        value: ''
    },
    {
        name: 'moTa',
        title: 'Mô tả',
        value: ''
    }
];

export const CurrentInsurance = [
    {
        name: 'maDKBD',
        title: 'Mã Đăng ký ban đầu',
        value: ''
    },
    {
        name: 'cqBHXH',
        title: 'cơ quan BHXH',
        value: ''
    },
    {
        name: 'maSoBHXH',
        title: 'Mã số BHXH',
        value : ''
    },
    {
        name: 'gtTheTu',
        title: 'T/g thẻ có giá trị sd',
        value: ''
    },
    {
        name: 'gtTheDen',
        title: 'Hạn thẻ',
        value: ''
    },
    {
        name: 'maKV',
        title: 'Mã khu vực',
        value: ''
    },
    {
        name: 'ngayDu5Nam',
        title: 'Ngày đủ 5 năm liên tục',
        value : ''
    },
];

export const TransferInsurance = [
    {
        name: 'maTheCu',
        title: 'Mã thẻ cũ',
        value: ''
    },
    {
        name: 'maTheMoi',
        title: 'Mã thẻ mới',
        value: ''
    },
    {
        name: 'gtTheTuMoi',
        title: 'T/g thẻ mới có giá trị sd',
        value : ''
    },
    {
        name: 'gtTheDenMoi',
        title: 'Hạn thẻ mới',
        value: ''
    },
    {
        name: 'maDKBDMoi',
        title: 'Mã DK ban đầu mới',
        value: ''
    },
    {
        name: 'tenDKBDMoi',
        title: 'Tên DK ban đầu mới',
        value: ''
    },
];
export const Note = [
    {
        name: 'ghiChu',
        title: 'Ghi chú',
        value: ''
    },
];

export const HistoryExamination = [];

export var HistoryExaminationItem = [
    {
        name: 'maHoSo',
        title: 'Mã HS',
        value: ''
    },
    {
        name: 'maCSKCB',
        title: 'Mã cơ sở KCB',
        value: ''
    }, 
    {
        name: 'ngayVao',
        title: 'Ngày vào',
        value: ''
    },
    {
        name:'ngayRa',
        title: 'Ngày ra',
        value: ''
    },
    {
        name: 'tenBenh',
        title: 'Tên bệnh',
        value: ''
    },
    {
        name: 'tinhTrang',
        title: 'Tình trạng ra viện',
        value: ''
    },
    {
        name: 'kqDieuTri',
        title: 'Kết quả điều trị',
        value: ''
    }
];