<template>
  <v-card 
    class="w-100" 
    elevation="0" 
    outlined>
      <Header>
          {{ title }}
      </Header>
      <v-sheet>
          <Container v-for="(item ,index) in listData" :key="index">
              <Item>
                  <SubTitle>
                      {{ item.title }}:
                  </SubTitle>
                  <Content>
                      {{ item.value }}
                  </Content>
              </Item>
          </Container>
      </v-sheet>
  </v-card>
</template>

<script>
import { 
    Container,
    Item,
    SubTitle,
    Content,
    Header
} from './css/style'
export default {
    components: {
        Container,
        Item,
        SubTitle,
        Content,
        Header
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        listData: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style>

</style>