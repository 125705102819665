import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas);

var prefix = "/SocialInsurance"

export default {
    checkHistoryExamination: (bodyReq) => httpClient.post(`${prefix}/Check-History-Examination`, {}, bodyReq)
}

