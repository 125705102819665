import styled from 'vue-styled-components';

export const Title = styled.span`
    font-family: 'Be Vietnam',sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0,0,0,0.87);
    width: 100%;
`;

export const Container = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    width: 100%;
`;

export const Item = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const InsuranceText = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;