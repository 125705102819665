<template >
  <v-container class="pa-0 ma-0">
    <v-form ref="createBookingForm">
      <v-row class="pl-4 pr-4">
        <v-col cols="12" md="8">
          <v-autocomplete
            v-model="serviceID"
            :rules="requiredRules"
            :items="services"
            filled
            :label="$t('Service.ServiceName')"
            append-icon="mdi-clipboard-plus-outline"
          ></v-autocomplete
        ></v-col>
        <v-col cols="4">
          <v-select
            v-model="selections.admissionType"
            :rules="requiredRules"
            :items="options.admissionTypes"
            filled
            :label="$t('Admission.AdmissionType')"
            append-icon="mdi-plus-outline"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="pl-4 pr-4">
        <v-col cols="12" md="12" class="pt-0 mt-0">
          <v-textarea
            v-model="chiefCompain"
            filled
            :label="$t('Common.ChiefComplains')"
            append-icon="mdi-message-text-outline"
            rows="3"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="4" class="pt-0 mb-5">
          <v-combobox
            v-model="selections.chronicDiseases"
            :items="options.chronicDiseases"
            hide-details
            class="ma-0 mb-1"
            label="Chronic Diseases"
            multiple
            filled
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="4" class="pt-0 mb-5">
          <v-combobox
            v-model="selections.allergies"
            :items="options.allergies"
            hide-details
            class="ma-0 mb-1"
            :label="$t('Common.Allergies')"
            multiple
            filled
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="4" class="pt-0 mb-5">
          <v-combobox
            v-model="selections.personalBehaviour"
            :items="options.personalBehaviour"
            hide-details
            class="ma-0 mb-1"
            :label="$t('Common.PersonalBehaviour')"
            multiple
            filled
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="4" class="pt-0 mb-5">
          <v-combobox
            v-model="selections.familyHistory"
            :items="options.familyHistory"
            hide-details
            class="ma-0 mb-1"
            :label="$t('Common.FamilyHistory')"
            multiple
            filled
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="4" class="pt-0 mb-5">
          <v-combobox
            v-model="selections.pastHistory"
            :items="options.pastHistory"
            hide-details
            class="ma-0 mb-1"
            :label="$t('Common.PersonalBehaviour')"
            multiple
            filled
            dense
          ></v-combobox>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="pl-3 pr-3" justify="center">
      <v-btn width="150px" @click="onCancelCreate" text> {{ $t('Button.Cancel') }} </v-btn>
      <v-btn
        :loading="isLoading"
        width="150px"
        @click="createNewCheckIn"
        color="primary"
      >
        {{ $t('Button.Create') }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ServiceService from "../../../services/service";
import BookingService from "../../../services/booking";

import moment from "moment";
import LookupService from "../../../services/lookup";
import EntitiesService from "../../../services/entities";
import { requiredRules } from "../../../plugins/rules";
import { BookingAdditionalStatType } from "../../../plugins/constant";
export default {
  components: {},
  data() {
    return {
      requiredRules,
      isLoading: false,
      options: {
        admissionTypes: [],
        chronicDiseases: [],
        allergies: [],
        personalBehaviour: [],
        pastHistory: [],
        familyHistory: [],
      },
      selections: {
        admissionType: "",
        chronicDiseases: [],
        allergies: [],
        personalBehaviour: [],
        pastHistory: [],
        familyHistory: [],
      },
      services: [],
      patientID: null,
      serviceID: null,
      clinicID: this.$store.getters.userData.clinicID,
      chiefCompain: "",
      time: moment().startOf("hour").add(2, "hours").format("HH:mm"),
      date: moment().format("DD/MM/YYYY"),
    };
  },
  computed: {},
  watch: {},
  async created() {
    this.renderListServices();
    this.renderListChronicDiseases();
    this.renderListAllergies();
    this.renderListFamilyHistories();
    this.renderListPastHistories();
    this.renderListPersonalBehaviour();
    await this.renderListAdmissionTypes();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    cleanForm() {
      if (this.patients.length) {
        this.patientID = this.patients[0].value;
      }
      this.serviceID = null;
    },
    onCancelCreate() {
      this.$emit("onCancelCreate", true);
    },
    async createNewCheckIn() {
      if (!this.serviceID) {
        this.showError("Missing required information. Complete and try again!");
        return;
      }
      this.isLoading = true;
      var timeBooking = moment().format("YYYY-MM-DD HH:mm");
      var additionalInfos = [];
      this.selections.allergies.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.Allergy,
        })
      );
      this.selections.chronicDiseases.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.ChronicDisease,
        })
      );
      this.selections.personalBehaviour.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.PersonalBehaviour,
        })
      );
      this.selections.pastHistory.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.PastHistory,
        })
      );
      this.selections.familyHistory.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.FamilyHistory,
        })
      );
      const bookingData = {
        additionalInfos,
        systemicConditions: "",
        clinicID: this.clinicID,
        serviceID: this.serviceID,
        patientID: this.patientID,
        timeStart: timeBooking,
        timeEnd: timeBooking,
        chiefCompain: this.chiefCompain,
        bookingType: this.selections.admissionType,
        isWalkIn: true,
      };
      var result = await BookingService.create(bookingData);
      this.isLoading = false;
      if (result.error) {
        this.showError("Cannot create new booking. Please try again later.");
        return;
      }
      this.$emit("onCreateSuccess", result);
    },
    async renderListServices() {
      if (!this.clinicID) return;
      const result = await ServiceService.searchAvailableByClicnicID(
        this.clinicID
      );
      if (result.error) {
        this.showError("Can not get list services. Please try again later.");
        return;
      }
      this.services = result.items.map((i) => ({
        value: i.id,
        text: i.description,
      }));
    },
    async renderListAdmissionTypes() {
      var result = await LookupService.searchAdmissionTypes();
      if (result.error) {
        this.showError(
          "Can not get list admission types. Please try again later."
        );
        return;
      }
      this.options.admissionTypes = result.items.map((i) => ({
        ...i,
        text: i.codeDescription,
        value: i.admissionTypeID,
      }));
      this.selections.admissionType =
        this.options.admissionTypes[0] &&
        this.options.admissionTypes[0].admissionTypeID;
    },
    async renderListChronicDiseases() {
      var result = await EntitiesService.listChronicDisease("", 1, 10000);
      if (result.error) {
        this.showError(
          "Can not get list chronic diseases. Please try again later."
        );
        return;
      }
      this.options.chronicDiseases = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
    async renderListAllergies() {
      var result = await EntitiesService.listAllergy("", 1, 10000);
      if (result.error) {
        this.showError("Can not get list allergies. Please try again later.");
        return;
      }
      this.options.allergies = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
    async renderListFamilyHistories() {
      var result = await EntitiesService.listFamilyHistory("", 1, 10000);
      if (result.error) {
        this.showError(
          "Can not get list family histories. Please try again later."
        );
        return;
      }
      this.options.familyHistory = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
    async renderListPastHistories() {
      var result = await EntitiesService.listPastHistory("", 1, 10000);
      if (result.error) {
        this.showError(
          "Can not get list past histories. Please try again later."
        );
        return;
      }
      this.options.pastHistory = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
    async renderListPersonalBehaviour() {
      var result = await EntitiesService.listPersonalBehaviour("", 1, 10000);
      if (result.error) {
        this.showError(
          "Can not get list personal behaviour. Please try again later."
        );
        return;
      }
      this.options.personalBehaviour = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
  },
};
</script>

<style scoped>
.row > div,
.col > div {
  padding-bottom: 0px !important;
}
</style>