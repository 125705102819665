import styled from 'vue-styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
`;

export const Item = styled.div`
    align-items: center;
    display: flex;
    padding: 5px 0;
`;

export const SubTitle = styled.div`
    font-size: 14px; 
    font-weight: normal;
    width: 35%;
    display: flex;
    align-items: center;
    margin: 0 20px 0 20px;
`;

export const Content = styled.div`
    width: 65%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
`;

export const Header = styled.div`
    display: block;
    padding: 10px 20px 10px 20px;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
`;