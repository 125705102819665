import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr)

export default {
  async search(keySearch = "", typeID = "", targetID = "", page = 1, limit = 10) {
    var query = {
      typeID,
      targetID,
      keySearch,
    }
    var headers = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get(`/Promotion/Search`, query, headers);
  },
}