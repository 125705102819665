<template>
    <v-select
        v-bind="$props"
        v-model="insuranceCompany.selected"
        :items="insuranceCompany.items"
        :label="$t('Common.InsuranceCompany')"
        :item-text="insuranceCompany.display"
        :disabled="disabled"
        :error="insuranceCompany.error"
        :error-messages="insuranceCompany.error_message"
        clearable
        required
        persistent-hint
        return-object
        :hint="insuranceCompany.hint"
    ></v-select>
</template>

<script>
import InsuranceCompanyBusiness from '../../../../services/insuranceCompany';
export default {
    props: {
        selectedID: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        'insuranceCompany.selected': {
            deep: true,
            handler() {
                if (typeof this.insuranceCompany.selected == 'undefined') {
                    this.insuranceCompany.selected = null;
                    this.insuranceCompany.hint = '';
                }
                if (this.insuranceCompany.selected != null) {   
                    this.insuranceCompany.hint = this.insuranceCompany.selected.codeDescription;
                    this.$emit('input', this.insuranceCompany.selected.insuranceCompanyID)
                } else {
                    this.$emit('input', null);
                }
            }
        },
    },
    data: () => {
        return {
            insuranceCompany: {
                items: [],
                error: false,
                error_message: '',
                display: 'insuranceCompanyCode',
                hint: '',
                selected: null
            }, 
        }
    },
    created() {
        this.loadListInsuranceCompany();
        
    },
    methods: {
        async loadListInsuranceCompany() { 
            
            var result = await InsuranceCompanyBusiness.searchList('', 1, -1); // keysearch, page, limit
            if (result.error) {
                this.insuranceCompany.error = true;
                this.insuranceCompany.error_message = 'Can not load!';
                return;
            }
            this.insuranceCompany.items = result.items;
            this.findCompanySelected();
        },

        findCompanySelected() {
            if (this.selectedID != null) {
                this.insuranceCompany.selected = this.insuranceCompany.items.find((item) => {
                    if (item.insuranceCompanyID == this.selectedID) {
                        return item;
                    }
                });
            }
        }
    }
}
</script>

<style>

</style>