import moment from "moment";
import i18n from "@/plugins/i18n";

export const nameRules = [
  v => !!v || i18n.t('Required.NameIsRequired'),
]
export const emailRules = [
  v => !!v || i18n.t('Required.EmailIsRequired'),
  v => /.+@.+/.test(v) || i18n.t('Required.EmailNotValid'),
]
export const dateRules = [
  v => !!v || i18n.t('Required.DateIsRequired'),
  v => v != i18n.t('Required.InvalidDate') || i18n.t('Required.FormatedToDDMMYYY')
]
export const dateFormatedRules = [
  v => !!v || i18n.t('Required.DateIsRequired'),
  v => moment(v, "DD/MM/YYYY").isValid() || i18n.t('Required.FormatedToDDMMYYY')
]
export const requiredRules = [
  v => !!v || i18n.t('Required.RequiredField'),
]
export const phoneRules = [
  v => !!v || i18n.t('Required.RequiredField'),
  v => v && v.length > 9 || i18n.t('Required.Minimum10Digits'),
  v => /^(0|[0-9]\d*)$/.test(v) || i18n.t('Required.AcceptNumbersOnly'),
]
export const identityNumberRules = [
  v => !!v || i18n.t('Required.RequiredField'),
  v => v && v.length > 8 || i18n.t('Required.Minimum9Digits'),
  v => /^(0|[0-9]\d*)$/.test(v) || i18n.t('Required.AcceptNumbersOnly'),
]