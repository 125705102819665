import firebaseConfig from './firebase.cofig'
import firebase from 'firebase'

firebase.initializeApp(firebaseConfig);
// Get a reference to the storage service, which is used to create references in your storage bucket
var storage = firebase.storage();

// Create a storage reference from our storage service
var storageRef = storage.ref();

export default {
  singleUpload(file, name, onProgressFn) {
    var path = `public/${name}`;
    var uploadTask = storageRef.child(path).put(file);
    return new Promise((resolve, reject) => {
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        // on uploadString progress, 
        function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (onProgressFn) {
            onProgressFn(progress)
          }
        }, 
        function(error) {
          return reject(error)
        }, 
        function() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            return resolve(downloadURL);
          });
        });
    });
  },
  uploadImageFile(file, name, onProgressFn) {
    var path = `public/${name}`;
    var uploadTask = storageRef.child(path).putString(file, 'data_url');
    return new Promise((resolve, reject) => {
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        // on upload progress, 
        function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (onProgressFn) {
            onProgressFn(progress)
          }
        }, 
        function(error) {
          return reject(error)
        }, 
        function() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            return resolve(downloadURL);
          });
        });
    });
  }
}