<template>
    <div class="d-block w-100">
        <div 
            class="d-flex align-center"
            v-if="loading"    
            align-center
            >
            <v-progress-circular
                indeterminate
                color="primary"
                class="mr-3"
            ></v-progress-circular>
            <span class="primary--text">{{ $t('Common.Checking') }}</span>
        </div>

        <v-dialog
            v-if="!loading"
            v-model="dialog"
            width="1200"
            >

            <template v-slot:activator="{ on, attrs }">
                <span
                    v-bind="attrs"
                    v-on="on" 
                    class="text--subtitle primary--text" 
                    style="cursor: pointer">
                    <v-icon
                        class="mr-2"
                        color="primary"
                       
                        >
                        mdi-card-account-details-outline
                    </v-icon>
                    <span>
                        {{ $t('Common.ViewChecking') }}
                    </span>
                </span>
            </template>
          
            <v-card elevation="0" v-if="!loading">
                <v-card-title class="font-weight-bold" >
                    <v-icon
                        class="mr-2"
                        color="black"
                        >
                        mdi-file-document-outline
                    </v-icon>
                    {{ $t('Common.SocialInsuranceInformation') }}
                </v-card-title>

                <v-divider></v-divider>
                
                <v-sheet elevation="0" class="pa-2 mt-2">
                    <!-- Show alert when can not retrieve from API -->
                    <v-alert
                        v-model="status.show"
                        dismissible
                        :type="status.type"
                        icon="mdi-alert"
                        >
                        {{ status.message }}
                    </v-alert>
                    
                    <v-row justify="space-between">
                        <!-- Status - PatientInfo - Note -->
                        <v-col cols="6" sx="12">
                            <CardInsurance 
                                :title="$t('Common.Status')"
                                :listData="dataRender.status"
                            />

                            <CardInsurance 
                                class="mt-3"
                                :title="$t('Patient.PatientInformation')"
                                :listData="dataRender.information"
                            />

                            <CardInsurance 
                                class="mt-3"
                                :title="$t('Common.Note')"
                                :listData="dataRender.note"
                            />
                        </v-col>

                        <!-- Insurance Number - Transfer -->
                        <v-col cols="6" sx="12">
                            <CardInsurance 
                                :title="$t('Common.CurrentInsuranceCard')"
                                :listData="dataRender.currentInsurance"
                            />
                            <CardInsurance 
                                class="mt-3"
                                :title="$t('Common.TransferAnotherInsuranceCard')"
                                :listData="dataRender.transferInsurance"
                            />
                        </v-col>
                    </v-row>
                </v-sheet>
                
                <v-card-title class="font-weight-bold" >
                    <v-icon
                        class="mr-2"
                        color="black"
                        >
                        mdi-file-document-outline
                    </v-icon>
                        {{ $t('Common.HistoryExamination') }}
                </v-card-title>

                <v-divider></v-divider>
                <v-sheet elevation="0" class="pa-2 mt-2">

                    <v-row v-if="(emptyHistoryExamination() == false)">
                        <v-col cols="4" v-for="(item, index) in dataRender.historyExamination" :key="index">
                            <CardInsurance 
                                :listData="item"
                            />
                        </v-col>
                    </v-row>
                </v-sheet>
                
            </v-card>
                   
        </v-dialog>
    </div>
   
</template>

<script>
import SocialInsuranceBusiness from '../../../../../services/socialInsurance';
//import {InsuranceText} from '../css/style';
import moment from "moment";
import _ from "lodash";
import CardInsurance from '../cardInsurrance/CardInsurance';
import { 
    Information, 
    Status, 
    CurrentInsurance, 
    TransferInsurance,
    Note,
    HistoryExamination,
    HistoryExaminationItem
    } from './dataEntry.js'
export default {
    components: {
       // InsuranceText 
       CardInsurance      
    },
    props: {
        birthDate: {
            type: String,
            default: ''
        },
        fullName: {
            type: String,
            default: ''
        },
        healthInsuranceNumber: {
            type: String,
            default: ''
        },
        isPublicInsurance: {
            type: Boolean,
            default: true
        }
    },
    
    mounted() {
        this.checkInsuranceCard();
    },
    data: () => {
        return {
            dialog: false,

            mapping: {
                status: ['maKetQua', 'moTa'],
                information: ['hoTen','ngaySinh', 'gioiTinh', 'diaChi'],
                currentInsurance: ['maDKBD','cqBHXH','gtTheTu','gtTheDen','maKV', 'ngayDu5Nam','maSoBHXH',],
                transferInsurance: ['maTheCu','maTheMoi','gtTheTuMoi','gtTheDenMoi','maDKBDMoi','tenDKBDMoi'],
                note: ['ghiChu'],
                historyExamination: ['dsLichSuKCB2018']
                //insurance: ['maDKBD', 'cqBHXH', 'gtTheTu', 'gtTheDen']
            },

            dataRender: {
                status: Status,
                information: Information,
                currentInsurance: CurrentInsurance,
                transferInsurance: TransferInsurance,
                note: Note,
                historyExamination: HistoryExamination
            },

            data: {
                maKetQua: null,
                moTa: null,

                ghiChu: null,

                maThe: null,
                hoTen: null,
                ngaySinh: null,
                gioiTinh:   null,
                diaChi: null,

                maDKBD: null,
                cqBHXH: null,
                gtTheTu: null,
                gtTheDen: null,
                maKV: null, 
                ngayDu5Nam: null,
                maSoBHXH: null,

                maTheCu:  '',
                maTheMoi:  null,
                gtTheTuMoi: null,
                gtTheDenMoi: null,
                maDKBDMoi: null,
                tenDKBDMoi: null,

                dsLichSuKCB2018: [],
                dsLichSuKT2018: null
            },

            loading: false,

            status: { 
                type: 'error',
                message: '',
                show: false
            }
        }
    },

    methods: {
        showStatus(msg) {
            this.status = { type: 'error', message: msg, show: true}
        },
        mapData() { 
            for (var property in this.mapping) {
                // console.log(typeof this.mapping[property])
                if (property != 'historyExamination')
                {
                    this.loopItem(property, this.mapping[property]);
                } else {
                    this.mapListHistoryExamination();
                }
               
            }
        },

        loopItem(key, listItem) {
            // lisItem should be a array
            listItem.forEach((item) => {
               
                if (typeof this.data[item] != 'undefined') {
                    this.dataRender[key].map(i => {
                        if (i.name == item) {
                            i.value = this.data[item];
                        }
                        return i;
                    });
                }
            });
        },

        async checkInsuranceCard() {
            this.loading = true;
            let formatDate = moment( this.birthDate).format('DD/MM/YYYY');   
           
            var bodyReq = {
                maThe: this.healthInsuranceNumber,
                hoTen: this.fullName,
                ngaySinh: formatDate
            };
            var result = await SocialInsuranceBusiness.checkHistoryExamination(bodyReq);
            console.log(result)
            if (result.error) {
                this.loading = false;
                this.showStatus('Something went wrong!');
                return;
            }
            this.loading = false;
            this.data = result;
            this.mapData();
          
        },

        // map list History Examination
        mapListHistoryExamination() {
            this.dataRender.historyExamination = this.data.dsLichSuKCB2018;
            
            // check data empty or not
            if (!_.isEmpty(this.dataRender.historyExamination)) {
                console.log('heee')
                var listHistoryExamination = [];
                this.dataRender.historyExamination.forEach(item => {
                    var result = this.prepareHistoryItem(item); 
                    listHistoryExamination.push(result);                   
                }); 
                //console.log(listHistoryExamination);
                this.dataRender.historyExamination = listHistoryExamination;
            }
        },

        prepareHistoryItem(itemHistory) {
           
            var itemHisExam = _.cloneDeep(HistoryExaminationItem);
            
            var result =  itemHisExam.map((item) =>  {
               if (item.name == 'ngayVao' || item.name == 'ngayRa') {
                   item.value = moment(itemHistory[item.name], 'YYYYMMDDhhmm').format("DD-MM-YYYY h:mm a")
               } else {
                    item.value = itemHistory[item.name];
               }
               return item;
           })
           return result;
        },

        emptyHistoryExamination() {
            // True ==> EMPTY, FALSE ==> NOT EMPTY
          
            if (_.isEmpty(this.dataRender.historyExamination))
                return true;
            return false;
        }
    },

}
</script>

<style>

</style>