import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

const http = new HttpClient(ApiGateway.Pas);

export default {
  listChronicDisease: (keySearch, page = 1, limit = 1000) => http.get(`/ChronicDisease/Search`, { keySearch }, { page, limit }),
  listAllergy: (keySearch, page = 1, limit = 1000) => http.get(`/Allergy/Search`, { keySearch }, { page, limit }),
  listFamilyHistory: (keySearch, page = 1, limit = 1000) => http.get(`/FamilyHistory/Search`, { keySearch }, { page, limit }),
  listPastHistory: (keySearch, page = 1, limit = 1000) => http.get(`/PastHistory/Search`, { keySearch }, { page, limit }),
  listPersonalBehaviour: (keySearch, page = 1, limit = 1000) => http.get(`/PersonalBehaviour/Search`, { keySearch }, { page, limit }),
}