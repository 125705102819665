import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import { AlertType } from '../plugins/constant'

var httpClient = new HttpClient(ApiGateway.Emr);

export default {
  checkin({ patientID, bookingID, admissionType, clinicID }, orderItems) {
    var body = {
      admission: {
        clinicID,
        patientID,
        bookingID,
        admissionTypeID: admissionType || 0,
        admissionTime: new Date(),
        alertID: AlertType.CheckIn,
        userInChargeID: 0,
        referralID: null,
        serviceGroupID: null,
      },
      itemOrders: orderItems,
    }
    return httpClient.post('/CheckIn/CheckIn', {}, body);
  },
  // NOTE: re-admission will delete old admission data and re-create new one
  // Only use it for beta system or for PAS system WITHOUT EMR
  reAdmission({ patientID, bookingID, admissionType, clinicID }, orderItems) {
    var body = {
      admission: {
        clinicID,
        patientID,
        bookingID,
        admissionTypeID: admissionType || 0,
        admissionTime: new Date(),
        alertID: AlertType.CheckIn,
        userInChargeID: 0,
        referralID: null,
        serviceGroupID: null,
      },
      itemOrders: orderItems,
    }
    return httpClient.post('/CheckIn/ReAdmission', {}, body);
  },
  updateOrderItemsIsFree({ patientID, bookingID, admissionType, clinicID }, orderItems) {
    var body = {
      admission: {
        clinicID,
        patientID,
        bookingID,
        admissionTypeID: admissionType || 0,
        admissionTime: new Date(),
        alertID: AlertType.CheckIn,
        userInChargeID: 0,
        referralID: null,
        serviceGroupID: null,
      },
      itemOrders: orderItems,
    }
    return httpClient.post('/CheckIn/UpdateOrderItemsIsFree', {}, body);
  },
  // NOTE: cancel-episode will delete old admission data
  cancelEpisode(episodeID) {
    return httpClient.put('/CheckIn/CancelEpisode/{episodeID}', { episodeID });
  },
  // NOTE: soft-delete episode 
  deleteEpisode(episodeID) {
    return httpClient.put(`/Episode/Cancel/${episodeID}`);
  },
  // API Get EpisodeRecordSummary
  getEpisodeRecordSummary(episodeRecordID) {
    return httpClient.get('/EpisodeRecord/GetSummary/{id}', { id: episodeRecordID });
  }
}