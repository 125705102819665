<template>
  <v-dialog v-model="isShow" :max-width="dialogWidth + 'px'">
    <v-card>
      <v-card-title class="headline">{{ $t('DialogConfirm.Confirm') }}</v-card-title>
      <v-card-text v-html="message"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="cancel">{{ $t('Button.Cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="agree" class="pl-6 pr-6">{{ $t('Button.Accept') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n";
export default {
  props: {
    message: {
      type: String,
      default: i18n.t('DialogConfirm.CompleteThisAction'),
    },
    dialogWidth: {
      type: Number,
      default: 520,
    },
  },
  data() {
    return {
      isShow: false,
      resolve: null,
    };
  },
  methods: {
    confirm() {
      this.isShow = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    agree() {
      this.resolve(true);
      this.isShow = false;
    },
    cancel() {
      this.resolve(false);
      this.isShow = false;
    },
  },
};
</script>
<style scoped>
.theme--light.v-card > .v-card__text {
  font-size: 105%;
}
</style>