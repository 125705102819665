import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas);

const prefix = '/InsuranceCompany';

export default {
    searchList: (keySearch, page, limit) => {
        var query = {
            keySearch
        };
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit
        };
        return httpClient.get(`${prefix}/Search`, query, headers);
    }
}   
