<template>
  <v-row class="ma-0">
    <v-col cols="4">
      <v-avatar color="grey lighten-2 fl mr-4" size="100" style="float: left">
        <v-img v-if="personalInfo.avatar" :src="personalInfo.avatar"></v-img>
        <v-icon v-else dark size="80px"> mdi-image-outline </v-icon>
      </v-avatar>
      <p class="mb-0 text-space">
        {{ $t("Patient.PatientName") }}: <b>{{ personalInfo.fullName }} </b>
      </p>
      <p class="mb-0 text-space">
        {{ $t("Patient.Gender") }}:
        <b>{{
          personalInfo.genderType == 1 ? $t("Common.Male") : $t("Common.Female")
        }}</b>
      </p>
      <p class="mb-0 text-space">
        {{ $t("Patient.Phone") }}: <b>{{ personalInfo.phone }}</b>
      </p>
      <p class="mb-0 text-space">
        {{ $t("Common.Email") }}: <b>{{ personalInfo.email }}</b>
      </p>
      <p class="mb-0 text-space">
        {{ $t("Patient.DOB") }}: <b>{{ formatDate(personalInfo.birthDate) }}</b>
      </p>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="4">
      <p class="mb-0 text-space">
        {{ $t("Common.ChronicDiseases") }}:
        <b> {{ booking.systemicConditions }}</b>
      </p>
      <p class="mb-0 text-space">
        {{ $t("Common.ChiefComplains") }}: <b> {{ booking.chiefCompain }} </b>
      </p>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="3">
      <p class="mb-0 text-space">
        {{ $t("Common.Type") }}: <b>{{ AdmissionType[booking.bookingType] }}</b>
      </p>
      <p class="mb-0 text-space">
        {{ $t("Common.Time") }}: <b>{{ formatTime(booking.timeStart) }}</b>
      </p>
      <p class="mb-0 text-space">
        {{ $t("Common.Code") }}: <b> {{ booking.codeBooking }}</b>
      </p>
      <p class="mb-0 text-space">
        {{ $t("Clinic.Clinic") }}: <b> {{ booking.clinicName }}</b>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import PatientService from "../../../services/patient";
import BookingService from "../../../services/booking";
import LookupService from "../../../services/lookup";

import { getGenderStatusCodeOptions } from "../../../plugins/helper";

export default {
  data() {
    return {
      AdmissionType: {
        0: "First-time",
        1: "Follow-up",
        2: "Emergency",
      },
      booking: {
        bookingID: 0,
        patientID: 0,
        serviceID: 0,
        codeBooking: "",
        timeStart: "",
        statusID: 0,
        chiefCompain: "",
        systemicConditions: "",
        bookingType: 0,
        isWalkIn: false,
      },
      personalInfo: {
        patientID: 0,
        fullName: "",
        phone: "",
        email: "",
        birthDate: "",
        genderType: "",
      },
    };
  },
  computed: {
    GenderTypeCodes: () => getGenderStatusCodeOptions(),
  },
  async mounted() {
    this.renderListAdmissionTypes();
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
    formatTime(date) {
      if (!date) return "";
      return moment(date).calendar();
    },
    showError(message) {
      this.$toast.error(message);
    },
    async reloadAdmissionDetail(bookingID, patientID) {
      // 1. render booking detail
      this.renderBookingDetail(bookingID);
      // 2. Lấy patientID để render patient detail
      this.renderPatientDetail(patientID);
    },
    async renderListAdmissionTypes() {
      var result = await LookupService.searchAdmissionTypes();
      if (result.error) {
        this.showError(
          "Can not get list admission types. Please try again later."
        );
        return;
      }
      this.AdmissionType = {};
      result.items.forEach((item) => {
        this.AdmissionType[item.admissionTypeID] = item.codeDescription;
      });
    },
    async renderBookingDetail(bookingID) {
      if (!bookingID) {
        return;
      }
      var result = await BookingService.getBookingDetail(bookingID);
      if (result.error) {
        this.showError("Can not get booking detail. Please try again later.");
        return;
      }
      this.booking = result;
    },
    async renderPatientDetail(patientID) {
      if (!patientID) {
        return;
      }
      var result = await PatientService.getPatientDetail(patientID);
      if (result.error) {
        this.showError("Can not get clinic detail. Please try again later.");
        return;
      }
      const { personalInfo } = result;
      const { fullName } = personalInfo;
      personalInfo.fullName = fullName;
      this.personalInfo = personalInfo;
    },
  },
};
</script>