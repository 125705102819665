<template >
  <v-main class="pt-0 pl-2 pr-2 form_format" light>
    <v-row>
      <v-col cols="12" md="6">
        <v-sheet rounded="lg" class="d-print-none">
          <v-row class="pl-3 pr-3">
            <v-card-title class="pl-6">{{
              $t("Common.PatientRegistration")
            }}</v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="handleCreatePatientClick"
              class="mt-3 mr-3 pa-2"
              style="float: right"
              color="primary"
            >
              <v-icon dark class="mr-2"> mdi-plus </v-icon>
              {{ $t("Patient.Create") }}
            </v-btn>
          </v-row>
          <div class="pl-3 pr-3">
            <SearchPatientOptions
              v-model="searchOptions"
              @input="renderPatientList"
            />
          </div>
          <v-skeleton-loader
            :loading="isSkeletonLoading"
            height="500"
            type="table"
          >
            <v-data-table
              :headers="list.headers"
              :items="list.items"
              :options.sync="list.options"
              :loading="list.loading"
              :server-items-length="list.totals"
              :footer-props="{
                tableFooterProps,
                'items-per-page-text': $t('Common.RowsPerPage'),
              }"
              @update:items-per-page="renderPatientList"
              @update:page="onChangePagination"
              disable-sort
              class="app-custom-table"
              mobile-breakpoint="0"
              :item-class="colorSelectedRow"
            >
              <template v-slot:[`item.fullName`]="{ item }">
                <v-avatar size="32px" color="primary">
                  <img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
                  <span v-else class="white--text">
                    {{ getNameInnitial(item.fullName) }}
                  </span>
                </v-avatar>
                <a
                  class="ml-2"
                  @click="handlePatientItemClick(item.patientID)"
                  >{{ item.fullName }}</a
                >
              </template>
              <template v-slot:[`item.genderType`]="{ item }">
                <GenderItem :genderType="item.genderType" />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="confirmDelete(item.patientID)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                {{ $t("EmptyData.SearchPatient") }}
              </template>
            </v-data-table>
          </v-skeleton-loader>
        </v-sheet>
        <PatientLastVisits ref="PatientLastVisits" />
      </v-col>
      <v-col cols="12" md="6" class="d-print-none">
        <v-sheet rounded="lg" class="pb-8">
          <v-window v-model="patientWindowID">
            <v-window-item :key="0">
              <NoDataFound
                ref="NoDataFound"
                :message="'Select a patient on the left to view!'"
                :default="true"
              >
                <PatientDetailInformation
                  ref="infoPatientForm"
                  :patientID="patientID"
                  @onEditClick="handleEditPatientClick"
                />
                <v-row class="pl-3 pr-3" justify="center">
                  <v-btn class="primary" width="150px" text @click="checkIn">
                    Check In
                  </v-btn>
                </v-row>
              </NoDataFound>
            </v-window-item>
            <v-window-item :key="1"
              ><v-card-title>
                {{ $t("Common.CheckInInformation") }}
              </v-card-title>
              <WalkInPatientForm
                ref="createBookingForm"
                @onCancelCreate="patientWindowID = 0"
                @onCreateSuccess="handleCreateBookingSuccess"
            /></v-window-item>
          </v-window>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="410px">
      <v-card>
        <v-card-title class="headline">{{
          $t("DialogConfirm.DeleteThisPatient")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t("Button.Cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteSelectedPatient">{{
            $t("Button.Accept")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreateAdmissionForm
      class="d-print-none"
      ref="admissionCard"
      @onCancelAdmission="
        dialogAdmission = false;
        patientWindowID = 0;
      "
      @onAdmissionSuccess="handleAdmissionSuccess"
    />
    <PatientProfilePopup
      class="d-print-none"
      ref="PatientProfilePopup"
      @onUpdateSuccess="handleUpdatePatientSuccess"
      @onCreateSuccess="handleCreatePatientSuccess"
    />
    <DialogConfirm
      ref="confirmCreateNewPatient"
      :message="'No patient can be found! Do you want to create new patient?'"
    />
  </v-main>
</template>

<script>
import PatientService from "../../services/patient";
import PatientDetailInformation from "../../components/commonComponents/PatientRegistration/PatientDetailInformation";
import PatientLastVisits from "@/components/commonComponents/PatientInfo/PatientLastVisits";
import WalkInPatientForm from "../../components/commonComponents/OnlineBooking/WalkInPatientForm";
import PatientProfilePopup from "../../components/commonComponents/PatientInfo/PatientProfilePopup";
import GenderItem from "../../components/commonComponents/Queue/components/GenderItem";
import CreateAdmissionForm from "../../components/commonComponents/Admission/CreateAdmissionForm";
import NoDataFound from "../../components/NoDataFound";
import DialogConfirm from "../../components/DialogConfirm";
import { TABLE_ROWS_PER_PAGE } from "../../plugins/constant";
import {
  getNameInnitial,
  getStatusColor,
  getStatusName,
} from "../../plugins/helper";
import SearchPatientOptions from "@/components/commonComponents/PatientRegistration/SearchPatientOptions";
import i18n from "@/plugins/i18n";

import moment from "moment";

export default {
  components: {
    WalkInPatientForm,
    PatientDetailInformation,
    GenderItem,
    CreateAdmissionForm,
    PatientProfilePopup,
    NoDataFound,
    DialogConfirm,
    PatientLastVisits,
    SearchPatientOptions,
  },
  data: () => ({
    tab: null,
    isSkeletonLoading: false,
    patientWindowID: 0,
    patientID: null,
    dialogDelete: false,
    tableFooterProps: {
      itemsPerPageOptions: TABLE_ROWS_PER_PAGE,
    },
    searchOptions: {
      keySearch: "",
      idSearch: "",
      aidSearch: "",
      phoneSearch: "",
      dobSearch: "",
    },
    list: {
      headers: [
        { text: i18n.t("Patient.FullName"), value: "fullName" },
        { text: i18n.t("Patient.Gender"), value: "genderType" },
        { text: i18n.t("Patient.DOB"), value: "dob" },
        { text: i18n.t("Patient.Phone"), value: "phoneNumber" },
        { text: i18n.t("Common.Address"), value: "address", width: "30%" },
        { text: "", value: "actions", sortable: false },
      ],
      totals: 0,
      items: [],
      loading: false,
      options: {
        itemsPerPage: 15,
      },
    },
  }),
  async mounted() {},
  methods: {
    getNameInnitial,
    getStatusColor,
    getStatusName,
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    confirmDelete(patientID) {
      this.patientID = patientID;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    resetSearchPatient() {
      this.list.totals = 0;
      this.list.items = [];
      this.list.loading = false;
      this.list.options.keySearch = "";
    },
    checkIn() {
      this.patientWindowID = 1;
      setTimeout(() => {
        this.$refs.createBookingForm.patientID = this.patientID;
      }, 1000);
    },
    handlePatientItemClick(patientID) {
      this.patientID = patientID;
      this.patientWindowID = 0;
      this.$refs.NoDataFound.hide();
      // setTimeOut because window is not created yet!
      let timeout = setTimeout(async () => {
        // render profile detail first
        await this.$refs.infoPatientForm.renderPatientDetail(patientID);
        // show last visit components
        var patientName = this.$refs.infoPatientForm.personalInfo.fullName;
        this.$refs.PatientLastVisits.renderLastVisits(patientID, patientName);
        clearTimeout(timeout);
      }, 200);
    },
    handleAdmissionSuccess(result, episodeRecordID) {
      this.dialogAdmission = false;
      this.patientWindowID = 0;
      /* Toast message on success (not showing 
      service name since there can be multiple) */
      var serviceNames = result.orderItems.map((i) => i.itemName).join(", ");
      this.showSuccess(
        `Patient ${result.admission.patientName} successfully registered to chosen services: ${serviceNames}`
      );
      // Display details of created patient
      this.handlePatientItemClick(this.patientID);
      this.$router.push({ path: `/queue/overviewid/${episodeRecordID}` });
    },
    handleUpdatePatientSuccess(patientID) {
      this.patientWindowID = 0;
      this.showSuccess("Successfully update patient profile.");
      // re-render list patients
      // this.renderPatientList();
      // Display details of created patient
      this.handlePatientItemClick(patientID);
    },
    handleCreatePatientSuccess(patientID) {
      this.patientWindowID = 0;
      this.showSuccess("Successfully create new patient profile.");
      // re-render list patients
      // this.renderPatientList();
      // Display details of created patient
      this.handlePatientItemClick(patientID);
    },
    handleCreateBookingSuccess(result) {
      var { bookingID, patientID, serviceID } = result;
      this.$refs.admissionCard.renderAdmissionDetail(
        bookingID,
        patientID,
        serviceID
      );
    },
    handleCreatePatientClick() {
      this.$refs.PatientProfilePopup.openCreate();
    },
    handleEditPatientClick() {
      this.$refs.PatientProfilePopup.openEdit(this.patientID);
    },
    colorSelectedRow(item) {
      return item.patientID == this.patientID ? "grey lighten-2" : "";
    },
    async deleteSelectedPatient() {
      var result = await PatientService.deletePatient(this.patientID);
      if (result.error) {
        this.showError("Cannot delete patients. Please try again later!");
        return;
      }
      this.closeDelete();
      this.renderPatientList();
    },
    async renderPatientList() {
      const { page, itemsPerPage } = this.list.options;
      const {
        keySearch,
        idSearch,
        aidSearch,
        phoneSearch,
        dobSearch,
        addressSearch,
      } = this.searchOptions;
      this.list.loading = true;
      var result = await PatientService.searchPatients(
        keySearch,
        idSearch,
        aidSearch,
        phoneSearch,
        dobSearch,
        addressSearch,
        page,
        itemsPerPage
      );
      this.list.loading = false;
      if (result.error) {
        this.showError("Cannot get list bookings. Please try again later!");
        return;
      }
      result.items.forEach((item) => {
        item.phoneNumber = item.phoneNumber || "N/A";
        item.email = item.email || "N/A";
        item.address = item.address || "N/A";
        item.statusID = this.list.options.statusID;
        item.dob = moment(item.dob).format("DD-MM-YYYY");
      });
      this.list.items = result.items;
      this.list.totals = result.totals;

      // Display empty data
      if (!result.items.length) {
        this.$refs.NoDataFound.show("Opps! No patients can be found.");
        // If search is filled and no data can be found
        if (this.list.options.keySearch) {
          // if confirm create new patient
          if (await this.$refs.confirmCreateNewPatient.confirm()) {
            // open popup create new patient
            this.handleCreatePatientClick();
          }
          // if cancel create
          else {
            // clear search options to re-render list patient
            this.list.options.keySearch = "";
            this.renderPatientList();
          }
        }
        return;
      } else {
        this.$refs.NoDataFound.show("Select a patient on the left to view!");
      }
    },
    async onChangePagination(value) {
      this.list.options.page = value;
      await this.renderPatientList();
    },
  },
};
</script>

<style lang="sass">
.form_format
  .row
    margin: 0px
</style>