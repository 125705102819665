<template >
  <div class="d-block w-100 h-100">
    <v-container fill-height fluid class="blury" v-show="isShow">
      <v-row align="center" justify="center">
        <span class="message">{{ messageString }}</span>
        <!-- <span>
          <v-img
            class="image"
            src="@/assets/nodata.png"
            height="480px"
            width="420px"
          />
        </span> -->
      </v-row>
    </v-container>
    <v-sheet class="pa-0 ma-0 w-100" v-show="!isShow">
      <slot></slot>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "Data cannot be found",
    },
    default: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: this.default,
      messageString: this.message,
    };
  },
  methods: {
    show(message) {
      if (message) this.messageString = message;
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
  },
};
</script>
<style scoped>
.blury {
  opacity: 0.6;
  overflow: hidden;
}
.image {
  margin-top: -50px;
}
.message {
  width: 100%;
  display: inline-block;
  font-size: 110%;
  text-align: center;
  margin-top: -80px;
  z-index: 2;
}
</style>
