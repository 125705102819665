<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        label="Chronic Disease"
        :disabled="!editable"
        :items="listChronicDiseases"
        v-model="personalHistory.chronicDiseaseCode"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12">
      <v-text-field
        label="Diagnosis History"
        :disabled="!editable"
        v-model="personalHistory.diagnosisDescription"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { getGenderStatusCodeOptions } from "../../../plugins/helper";
import PatientService from "../../../services/patient";

export default {
  props: {
    personalHistory: {
      type: Object,
      default: () => ({
        personalHistoryID: 0,
        patientID: 0,
        chronicDiseaseCode: "",
        diagnosisDescription: "",
      }),
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listChronicDiseases: [],
    };
  },
  watch: {
    // emit change event to parent
    personalHistory: {
      deep: true,
      handler(val) {
        this.$emit("onChange", val);
      },
    },
  },
  components: {},
  computed: {
    GenderTypeCodes: () => getGenderStatusCodeOptions(),
  },
  async mounted() {
    this.renderListChronicDiseaseOptions();
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
    async renderListChronicDiseaseOptions() {
      var result = await PatientService.getChronicDiseasesOption("", 1, 1000);
      if (result.error) {
        this.showError(
          "Can not retrieve chronic diseases list. Please try again later."
        );
        return;
      }
      this.listChronicDiseases = result.items.map((disease) => ({
        text: disease.description,
        id: disease.id,
        value: disease.code,
      }));
    },
    showError(message) {
      this.$toast.error(message);
    },
  },
};
</script>
<style scoped>
.theme--light.v-input--is-disabled * {
  color: rgba(0, 0, 0, 1) !important;
}
</style>